import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import * as COMMONS from "common/common";
import { AnimatePresence } from "framer-motion";
import TopProgressBar from "components/common/TopProgressBar";
import ErrorBoundary from "components/common/ErrorBoundary";

const AdminLayout = lazy(() => import("./layouts/AdminLayout"));
const ClientLayout = lazy(() => import("./layouts/ClientLayout"));
const MinimalLayout = lazy(() => import("./layouts/Minimal"));
const MobileLayout = lazy(() => import("./layouts/MobileLayout"));

const AdminLoginView = lazy(() => import("./views/Admin/Login"));
const AdminApplicantsView = lazy(() => import("./views/Admin/Applicants"));
const AdminStoresView = lazy(() => import("./views/Admin/Stores"));
const AdminSchedulesView = lazy(() => import("./views/Admin/Schedules"));
const AdminInterviewsView = lazy(() => import("./views/Admin/Interviews"));
const AdminAudiencesView = lazy(() => import("./views/Admin/Audiences"));
const AdminManagersView = lazy(() => import("./views/Admin/Managers"));
const AdminSettingsView = lazy(() => import("./views/Admin/Settings"));

const ClientLoginView = lazy(() => import("./views/Client/Login"));
const ClientApplicantView = lazy(() => import("./views/Client/Applicant"));
const ClientWelcomeView = lazy(() => import("./views/Client/Welcome"));
const ClientRecruitment = lazy(() =>
  import("./views/Client/Recruitment")
);
const ClientRecruitmentTwo = lazy(() =>
  import("./views/Client/RecruitmentTwo")
);
const ClientRecruitmentThree = lazy(() =>
  import("./views/Client/RecruitmentThree")
);
const ClientRecruitmentFive = lazy(() =>
  import("./views/Client/RecruitmentFive")
);
const ClientRecruitmentSix = lazy(() =>
  import("./views/Client/RecruitmentSix")
);
const ClientRecruitmentSeven = lazy(() =>
  import("./views/Client/RecruitmentSeven")
);
const ClientRecruitmentEight = lazy(() =>
  import("./views/Client/RecruitmentEight")
);
const ClientRecruitmentEleven = lazy(() =>
  import("./views/Client/RecruitmentEleven")
);  
const ClientRecruitmentNine = lazy(() =>
  import("./views/Client/RecruitmentNine")
);
const ClientRecruitmentTen = lazy(() =>
  import("./views/Client/RecruitmentTen")
);
const ClientRecruitmentTwelve = lazy(() =>
  import("./views/Client/RecruitmentTwelve")
);

const LineAccessView = lazy(() => import("./views/LineAccess"));
const LineFriendView = lazy(() => import("./views/LineFriend"));
const PermissionErrorView = lazy(() => import("./views/PermissionError"));
const NotFoundView = lazy(() => import("./views/NotFound"));

const Router = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<TopProgressBar />}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to={COMMONS.ADMIN_LOGIN_ROUTE} />}
            />
            <Route
              path={COMMONS.ADMIN_LOGIN_ROUTE}
              element={
                <MinimalLayout>
                  <AdminLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_STORES_ROUTE}
              element={
                <AdminLayout>
                  <AdminStoresView />
                </AdminLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_APPLICANTS_ROUTE}/:id`}
              element={
                <AdminLayout>
                  <AdminApplicantsView />
                </AdminLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_SCHEDULES_ROUTE}/:id`}
              element={
                <AdminLayout>
                  <AdminSchedulesView />
                </AdminLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_INTERVIEWS_ROUTE}/:id`}
              element={
                <AdminLayout>
                  <AdminInterviewsView />
                </AdminLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_AUDIENCES_ROUTE}/:id`}
              element={
                <AdminLayout>
                  <AdminAudiencesView />
                </AdminLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_MANAGERS_ROUTE}
              element={
                <AdminLayout>
                  <AdminManagersView />
                </AdminLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_SETTINGS_ROUTE}
              element={
                <AdminLayout>
                  <AdminSettingsView />
                </AdminLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_LOGIN_ROUTE}/:liffId`}
              element={
                <ClientLayout>
                  <ClientLoginView />
                </ClientLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_APPLICANT_ROUTE}/:liffId/:storeId`}
              element={
                <ClientLayout>
                  <ClientApplicantView />
                </ClientLayout>
              }
            />
            <Route
              path={COMMONS.LINE_ACCESS_ROUTE}
              element={
                <MinimalLayout>
                  <LineAccessView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.LINE_FRIEND_ROUTE}
              element={
                <MinimalLayout>
                  <LineFriendView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.PERMISSION_ERROR_ROUTE}
              element={
                <MinimalLayout>
                  <PermissionErrorView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.NOT_FOUND_ROUTE}
              element={
                <MinimalLayout>
                  <NotFoundView />
                </MinimalLayout>
              }
            />
            <Route
              path="*"
              element={<Navigate replace to={COMMONS.NOT_FOUND_ROUTE} />}
            />
            <Route
              path={COMMONS.CLIENT_REGISTER_ROUTE}
              element={
                <MobileLayout>
                  <ClientWelcomeView />
                </MobileLayout>
              }
            />

            <Route
              path={COMMONS.CLIENT_RECRUITMENT_TWO_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentTwo />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_THREE_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentThree />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_FIVE_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentFive />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_SIX_ROUTE}
              element={
               
                  <ClientRecruitmentSix />
                
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_SEVEN_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentSeven />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_EIGHT_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentEight />
                </MobileLayout>
              }
            />
            <Route    
              path={COMMONS.CLIENT_RECRUITMENT_NINE_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentNine />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_TEN_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentTen />
                </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_ELEVEN_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentEleven />
                  </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMENT_TWELVE_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitmentTwelve />
                  </MobileLayout>
              }
            />
            <Route
              path={COMMONS.CLIENT_RECRUITMEN_ROUTE}
              element={
                <MobileLayout>
                  <ClientRecruitment />
                </MobileLayout>
              }
            />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
