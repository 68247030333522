import React from "react";
import ReactDOM from "react-dom/client";

import { ConfigProvider } from "antd";
import { CustomProvider } from "provider/CustomProvider";
import jaJP from "antd/locale/ja_JP";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import moment from "moment";
import "moment/locale/ja";

import App from "./App";

moment.locale("ja");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <CustomProvider>
      <ConfigProvider
        locale={jaJP}
        theme={{
          token: {
            colorPrimary: "#017440",
            borderRadius: 4,
            colorText: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ConfigProvider>
    </CustomProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
