import React, { useEffect, useRef, useState } from 'react';
import { Badge, Tag } from 'antd';
import { generate } from '@ant-design/colors';
import Resizer from 'react-image-file-resizer';
import moment from 'moment';

const DEEP_FREEZE = (obj) => {
	// Retrieve the property names defined on obj
	var propNames = Object.getOwnPropertyNames(obj);

	// Freeze properties before freezing self
	propNames.forEach(function (name) {
		var prop = obj[name];

		// Freeze prop if it is an object
		if (typeof prop == 'object' && prop !== null) DEEP_FREEZE(prop);
	});

	// Freeze self (no-op if already frozen)
	return Object.freeze(obj);
};

/* ROUTES */
export const ADMIN_LOGIN_ROUTE = '/login';
export const ADMIN_APPLICANTS_ROUTE = '/applicants';
export const ADMIN_STORES_ROUTE = '/stores';
export const ADMIN_SCHEDULES_ROUTE = '/schedule';
export const ADMIN_INTERVIEWS_ROUTE = '/interviews';
export const ADMIN_AUDIENCES_ROUTE = '/audiences';
export const ADMIN_MANAGERS_ROUTE = '/managers';
export const ADMIN_SETTINGS_ROUTE = '/settings';

export const CLIENT_LOGIN_ROUTE = '/liff/login';
export const CLIENT_APPLICANT_ROUTE = '/liff/applicant';
export const CLIENT_QUESTION_ROUTE = '/liff/question';
export const CLIENT_REGISTER_ROUTE = '/liff/register';
export const CLIENT_RECRUITMENT_ONE_ROUTE = '/liff/recruitment-one';
export const CLIENT_RECRUITMENT_TWO_ROUTE = '/liff/recruitment-two';
export const CLIENT_RECRUITMENT_THREE_ROUTE = '/liff/recruitment-three';
export const CLIENT_RECRUITMENT_FIVE_ROUTE = '/liff/recruitment-five';
export const CLIENT_RECRUITMENT_SIX_ROUTE = '/liff/recruitment-six';
export const CLIENT_RECRUITMENT_SEVEN_ROUTE = '/liff/recruitment-seven';
export const CLIENT_RECRUITMENT_EIGHT_ROUTE = '/liff/recruitment-eight';
export const CLIENT_RECRUITMENT_ELEVEN_ROUTE = '/liff/recruitment-eleven';
export const CLIENT_RECRUITMENT_NINE_ROUTE = '/liff/recruitment-nine';
export const CLIENT_RECRUITMENT_TEN_ROUTE = '/liff/recruitment-ten';
export const CLIENT_RECRUITMENT_TWELVE_ROUTE = '/liff/recruitment-twelve';
export const CLIENT_RECRUITMENT_THIRTEEN_ROUTE = '/liff/recruitment-thirteen';
export const CLIENT_RECRUITMEN_ROUTE = '/liff/recruitment';

export const LINE_ACCESS_ROUTE = '/line-access';
export const LINE_FRIEND_ROUTE = '/line-friend';
export const NOT_FOUND_ROUTE = '/404';
export const PERMISSION_ERROR_ROUTE = '/401';

/* REDIRECT URL */
export const REDIRECT_URL = ADMIN_LOGIN_ROUTE;

/* RESPONSE TYPE */
export const RESPONSE_PERMISSION_ERROR = 401;
export const RESPONSE_SESSION_ERROR = 403;
export const RESPONSE_NOT_ACCEPTABLE_ERROR = 406;
export const RESPONSE_CONFLICT_ERROR = 409;
export const RESPONSE_SYSTEM_ERROR = 500;

/* MESSAGE KEY */
export const MESSAGE_SESSION_ERROR_KEY = 'SESSION_ERROR';
export const MESSAGE_SYSTEM_ERROR_KEY = 'SYSTEM_ERROR';

/* PRIMARY COLOR */
export const PRIMARY_COLOR = '#017440';
export const PRIMARY_LIGHT_COLOR = '#fdfff0';
export const PROGRESS_COLOR = '#0F9295';
export const SECONDARY_COLOR = '#21acd7';
export const SECONDARY_LIGHT_COLOR = '#f0feff';
export const ORANGE_COLOR = '#fa8c16';
export const ORANGE_LIGHT_COLOR = '#fff7e6';
export const GREEN_COLOR = '#52c41a';
export const GREEN_LIGHT_COLOR = '#f6ffed';
export const CYAN_COLOR = '#13c2c2';
export const VOLCANO_COLOR = '#fa541c';
export const WHITE_COLOR = '#FFF';
export const GRAY_TEXT_COLOR = '#595959';
export const REJECT_BG_COLOR = '#ff7875';
export const REJECT_BORDER_COLOR = '#ff4d4f';
export const ACCEPT_BG_COLOR = '#95de64';
export const ACCEPT_BORDER_COLOR = '#73d13d';
export const LOCKED_BORDER_COLOR = '#bfbfbf';
export const CUSTOM_GRAY_COLOR = '#d9d9d9';
export const SATURDAY_COLOR = '#00c2ff';
export const SUNDAY_COLOR = '#c40055';

/* COMMON KEY */
export const QUESTION_STEP = 'QUESTION_STEP';

/** Checkbox Recruitment One */

export const RECRUITMENT_ONE = [
	{ label: 'LINE広告', value: '1' },
	{ label: 'タウンワーク', value: '2' },
	{ label: 'indeed', value: '3' },
	{ label: 'YouTube', value: '4' },
	{ label: 'Ticktok', value: '5' },
	{ label: 'HP', value: '6' },
	{ label: '紹介', value: '7' },
	{ label: 'その他', value: '8' },
];

/** Checkbox Recruitment Three */

export const RECRUITMENT_THREE = [
	{ label: '週1～2日でやってみたい', value: '1' },
	{ label: 'がっつり稼ぎたい', value: '2' },
	{ label: '短期希望（3ヶ月程です）', value: '3' },
	{ label: '平日に入りたい', value: '4' },
	{ label: '土日に入りたい', value: '5' },
	{ label: '日勤希望（8時～17時）', value: '6' },
	{ label: '夜勤希望（21時～翌6時）', value: '7' },
	{ label: '正社員も考えたい', value: '8' },
];

export const RECRUITMENT_NINE = [
	{
		label: (
			<p className="mt-1">
				本社 <br />
				<span className="text-[12px]">
					<span
						className="w-[230px] overflow-hidden"
						style={{
							display: '-webkit-box',
							webkitLineClamp: 2,
							webkitBoxOrient: 'vertical',
						}}
					>
						愛知県名古屋市東区白壁三丁目２４－５８
					</span>
					<span>東海セキュリティービル</span>
					<span
						className="w-[230px] overflow-hidden"
						style={{
							display: '-webkit-box',
							webkitLineClamp: 2,
							webkitBoxOrient: 'vertical',
						}}
					>
						🚆名鉄瀬戸線「尼ヶ坂」駅から徒歩6分
					</span>
				</span>
			</p>
		),
		value: '本社',
	},
	{
		label: (
			<p className="mt-1">
				三河営業所 <br />
				<span className="text-[12px]">
					<span>愛知県豊川市小坂井町門並６９－２</span>
					<span
						className="w-[230px] overflow-hidden"
						style={{
							display: '-webkit-box',
							webkitLineClamp: 2,
							webkitBoxOrient: 'vertical',
						}}
					>
						🚆JR飯田線「小坂井」駅から徒歩1分
					</span>
				</span>
			</p>
		),
		value: '三河営業所',
	},
	{
		label: (
			<p className="mt-1">
				岐阜営業所 <br />
				<span className="text-[12px]">
					<span>岐阜県各務原市那加織田町１－７</span>
					<span
						className="w-[230px] overflow-hidden"
						style={{
							display: '-webkit-box',
							webkitLineClamp: 2,
							webkitBoxOrient: 'vertical',
						}}
					>
						🚆名鉄各務原線「各務原市役所前」駅から徒歩3分
					</span>
				</span>
			</p>
		),
		value: '岐阜営業所',
	},
	{
		label: (
			<p className="mt-1">
				三重営業所 <br />
				<span className="text-[12px]">
					<span>三重県三重郡菰野町大字諏訪3129</span>
					<span
						className="w-[230px] overflow-hidden"
						style={{
							display: '-webkit-box',
							webkitLineClamp: 2,
							webkitBoxOrient: 'vertical',
						}}
					>
						🚆三岐鉄道三岐線「保々」駅から自転車で約15分
					</span>
				</span>
			</p>
		),
		value: '三重営業所',
	},
];

export const THEME_COLORS = DEEP_FREEZE([
	'#017440',
	'#EA638C',
	'#DB5461',
	'#BB3E03',
	'#E85D75',
	'#C76D7E',
	'#AB92BF',
	'#8D6B94',
	'#4EA5D9',
	'#134074',
	'#005F73',
	'#8DA9C4',
	'#72A1E5',
	'#0A9396',
	'#84A07C',
	'#3C787E',
	'#77878B',
	'#9a8c98',
	'#9F8082',
	'#b5838d',
	'#6d6875',
	'#30343F',
	'#2E382E',
]);

/* SCREEN SIZE */
export const XL_SCREEN_SIZE = 1224;

/* AUTH TYPE */
export const AUTH_MASTER = 'master';

/* GOOGLE API KEY */
export const GKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

/* FULLCALENDAR KEY */
export const FKEY = process.env.REACT_APP_FULLCALENDAR_API_KEY;
export const BUSINESS_OPEN_TIME = '06:00:00';
export const BUSINESS_OPEN_TIME_VALUE = 6;
export const BUSINESS_CLOSE_TIME = '23:00:00';
export const BUSINESS_CLOSE_TIME_VALUE = 23;
export const BUSINESS_INTERVAL_TIME_LABEL = '00:30:00';
export const BUSINESS_INTERVAL_TIME = '00:30:00';
export const BUSINESS_INTERVAL_TIME_VALUE = 30; //MUST BE MINUTE 15 | 30 | 45 | 60 ...

/* ALERT MESSAGES */
export const ERROR_LOGIN_MISMATCH_MSG =
	'ユーザー名またはパスワードが間違っています';
export const ERROR_SYSTEM_MSG = 'システムエラー';
export const ERROR_SYSTEM_DUPLICATE_AUDIENCE =
	'オーディエンス名が重複しています';
export const ERROR_SESSION_MSG = 'もう一度ログインしてください';
export const ERROR_LINE_403_MSG = 'LINEアプリからアクセスしてください';
export const ERROR_LINE_FRIEND_MSG = 'LINEで友だち追加してアクセスしてください';
export const ERROR_401_MSG = '申し訳ありませんが、許可が足りないです。';
export const ERROR_404_MSG =
	'申し訳ありませんが、アクセスしたページは存在しません。';
export const SUCCESS_LOGIN_MSG = 'ログインしました。';
export const SUCCESS_LOGOUT_MSG = 'ログアウトしました。';
export const SUCCESS_CREATE_MSG = '登録しました。';
export const SUCCESS_MAKE_MSG = '作成しました。';
export const SUCCESS_UPDATE_MSG = '変更しました。';
export const SUCCESS_SAVE_MSG = '保存しました。';
export const SUCCESS_DELETE_MSG = '削除しました。';
export const SUCCESS_SENT_MSG = '送信しました。';
export const SUCCESS_CANCEL_MSG = 'キャンセルしました。';
export const SUCCESS_SYNC_MSG = '同期しました。';

export const ERROR_NO_CHOICE = '選択肢は少なくとも1つでなければなりません';
export const ERROR_QUESTION_TYPE_ALREADY_EXIST = 'このステップ';
export const ERROR_SCHEDULE_ALREADY_EXIST =
	'応募者に面接時間がもう登録されています';
export const ERROR_USERNAME_ALREADY_EXIST = 'ユーザー名はすでに存在します';
export const ERROR_NO_STORE = '店舗が存在しません';
export const WARN_NO_SCHEDULE = '日時を選んでください';
export const WARN_WRONG_POSTAL = '郵便番号を確認してください';
export const WARN_NEED_ADDRESS = '住所を入力してください';
export const WARN_IMAGE_TOO_BIG = '画像サイズが大きすぎます';
export const WARN_IMAGE_SIZE_DIFFERENT = '画像サイズが違います';
export const WARN_NEED_LOCATION_PERMISSION =
	'地図の場所の許可を有効にしてください';
export const WARN_CAROUSEL_USED =
	'画像メッセージが通知メッセージに使われています';
export const WARN_AUDIENCE_NAME_EXIST_MSG =
	'このオーディエンス名はすでに存在しています';
export const WARN_AUDIENCE_COUNT_ZERO_MSG =
	'応募者0人でオーディエンスを作成することはできません';
export const WARN_AUDIENCE_NOT_MATCH_MSG =
	'検索条件が変更されています。一度検索ボタンクリックしてその後作成してください。';
export const WARN_PASSWORD_NOT_MATCH_MSG = 'パスワードが間違っています';
export const CONFIRM_ANSWER_MSG = 'お答えには間違いないでしょうか？';
export const CONFIRM_REJECT_MSG =
	'※応募者に送るLINEメッセージを入力してください。何も入力しない場合、応募者には何も送信されません';
export const CONFIRM_WAIT_MSG = '面接者が確認するまでお待ちください';
export const REQUEST_RESUME_START_MSG = '履歴書を記入してください';
export const REQUEST_RESUME_WAIT_MSG =
	'面接者が履歴書を確認するまでお待ちください';

// export const REQUEST_IDENTITY_MSG = '自分証明書の情報をご登録ください。';
export const REQUEST_IDENTITY_MSG = '身分証明書の情報をご登録ください。';

export const REQUEST_KOZA_MSG = '銀行口座の情報を入力してください';
export const REQUEST_AGREEMENT_MSG =
	'当店のルールやマニュアルに\n関して、すべて理解し同意します';

/* Days of week */
export const DAYS_OF_WEEK = DEEP_FREEZE([
	{
		label: '日',
		longLabel: '日曜日',
		value: 7,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '月',
		longLabel: '月曜日',
		value: 1,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '火',
		longLabel: '火曜日',
		value: 2,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '水',
		longLabel: '水曜日',
		value: 3,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '木',
		longLabel: '木曜日',
		value: 4,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '金',
		longLabel: '金曜日',
		value: 5,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
	{
		label: '土',
		longLabel: '土曜日',
		value: 6,
		times: [
			{
				startTime: moment(BUSINESS_OPEN_TIME, 'HH:mm:ss'),
				endTime: moment(BUSINESS_CLOSE_TIME, 'HH:mm:ss'),
			},
		],
		enabled: true,
	},
]);

/* QUESTION TYPE */
export const QUESTION_TYPE_START = 'start';
export const QUESTION_TYPE_CHOICE = 'choice';
export const QUESTION_TYPE_TEXT = 'text';
export const QUESTION_TYPE_DATE = 'date';
export const QUESTION_TYPE_IMAGE = 'image';
export const QUESTION_TYPE_LOCATION = 'location';
export const QUESTION_TYPE_MAP_LOCATION = 'mapLocation';
export const QUESTION_TYPE_APPOINTMENT = 'appointment';
export const QUESTION_TYPE_ADMIN_CONFIRM = 'adminConfirm';
export const QUESTION_TYPE_APPLICANT_CONFIRM = 'applicantConfirm';
export const QUESTION_TYPE_DONE = 'done';
export const QUESTION_TYPE_CHECKLIST = 'checklist';
export const QUESTION_TYPE_NAME = 'name';
export const QUESTION_TYPE_AGE = 'age';
export const QUESTION_TYPE_TEXT_BOX = 'textBox';
export const QUESTION_TYPE_CHECKBOX = 'checkbox';

export const QUESTION_TYPE_START_TEXT = '面接開始';
export const QUESTION_TYPE_DONE_TEXT = '面接完了';

// export const IS_LAST_QUESTION = (questions) => {
// 	return questions.findIndex((q) => isQuestionTypeApplicantConfirm(q.type)) !== -1
// 		? questions
// 				.slice(0, questions.findIndex((q) => isQuestionTypeApplicantConfirm(q.type)) + 1)
// 				.filter((q) => q.answers.length === 0).length === 1
// 			? true
// 			: false
// 		: questions.filter(
// 				(q) => q.type !== QUESTION_TYPE_ADMIN_CONFIRM && q.type !== QUESTION_TYPE_DONE && q.answers.length === 0
// 		  ).length === 1
// 		? true
// 		: false
// }

export const IS_LAST_QUESTION = (questions) => {
	//last done step is auto filling
	return questions.filter((q) => q?.answers?.length === 0)?.length === 2;
};

export const GET_CURRENT_QUESTION = (questions, application, back = 0) => {
	const currentQuestionIndex = questions.findIndex(
		(q) => q?.questionId === application?.nextQuestion,
	);
	return questions[currentQuestionIndex - back];
};

export const CHECK_IF_CONFIRM_NEEDED = (questions, application) => {
	const currentQuestion = GET_CURRENT_QUESTION(questions, application);

	if (
		isQuestionTypeStart(currentQuestion?.type) ||
		isQuestionTypeAdminConfirm(currentQuestion?.type) ||
		isQuestionTypeApplicantConfirm(currentQuestion?.type) ||
		isQuestionTypeDone(currentQuestion?.type)
	) {
		return false;
	} else {
		return (
			isQuestionTypeNeedConfirm(currentQuestion?.needConfirm) &&
			GET_CURRENT_QUESTION(questions, application)?.answers.length > 0
		);
	}
};

export const AUDIENCE_FILTERABLE_TYPES = DEEP_FREEZE([
	QUESTION_TYPE_CHOICE,
	QUESTION_TYPE_TEXT,
	QUESTION_TYPE_LOCATION,
	QUESTION_TYPE_MAP_LOCATION,
	QUESTION_TYPE_DATE,
	QUESTION_TYPE_APPOINTMENT,
]);

export const AUDIENCE_TEXT_FILTERS = DEEP_FREEZE([
	QUESTION_TYPE_TEXT,
	QUESTION_TYPE_LOCATION,
	QUESTION_TYPE_MAP_LOCATION,
	QUESTION_TYPE_CHOICE,
]);

export const AUDIENCE_DATE_FILTERS = DEEP_FREEZE([
	QUESTION_TYPE_DATE,
	QUESTION_TYPE_APPOINTMENT,
]);

export const isQuestionTypeStart = (type) => {
	return type === QUESTION_TYPE_START;
};

export const isQuestionTypeText = (type) => {
	return type === QUESTION_TYPE_TEXT;
};

export const isQuestionTypeDate = (type) => {
	return type === QUESTION_TYPE_DATE;
};

export const isQuestionTypeChoice = (type) => {
	return type === QUESTION_TYPE_CHOICE;
};

export const isQuestionTypeImage = (type) => {
	return type === QUESTION_TYPE_IMAGE;
};

export const isQuestionTypeLocation = (type) => {
	return type === QUESTION_TYPE_LOCATION;
};

export const isQuestionTypeMapLocation = (type) => {
	return type === QUESTION_TYPE_MAP_LOCATION;
};

export const isQuestionTypeAppointment = (type) => {
	return type === QUESTION_TYPE_APPOINTMENT;
};

export const isQuestionTypeAdminConfirm = (type) => {
	return type === QUESTION_TYPE_ADMIN_CONFIRM;
};

export const isQuestionTypeApplicantConfirm = (type) => {
	return type === QUESTION_TYPE_APPLICANT_CONFIRM;
};

export const isQuestionTypeDone = (type) => {
	return type === QUESTION_TYPE_DONE;
};

export const isQuestionTypeTextBox = (type) => {
	return type === QUESTION_TYPE_TEXT_BOX;
};

export const isQuestionTypeCheckbox = (type) => {
	return type === QUESTION_TYPE_CHECKBOX;
};

export const isQuestionTypeCheckList = (type) => {
	return type === QUESTION_TYPE_CHECKLIST;
};

export const isQuestionTypeName = (type) => {
	return type === QUESTION_TYPE_NAME;
};

export const isQuestionTypeAge = (type) => {
	return type === QUESTION_TYPE_AGE;
};

export const isQuestionTypeNeedConfirm = (needConfirm) => {
	return needConfirm === true;
};

export const checkAnswerIsExist = (application, question) => {
	return application.answers.find(
		(a) => question.questionId === a.questionId,
	);
};

export const getAnswerContents = (application, question, locations) => {
	if (question.type === 'checkbox' && locations) {
		const content =
			application.answers.find(
				(a) => question.questionId === a.questionId,
			)?.contents || '';
		return locations.find((o) => o.locationId === +content)?.name || '';
	}
	return (
		application.answers.find((a) => question.questionId === a.questionId)
			?.contents || ''
	);
};

export const getAnswerImage = (application, question) => {
	return (
		application.answers.find((a) => question.questionId === a.questionId)
			?.answerImages || ''
	);
};

/* APPLICATION STATUS */
export const APP_STATUS_START = 'start';
export const APP_STATUS_START_TEXT = '新規';
export const APP_STATUS_START_COLOR = '#38a3a5';
export const APP_STATUS_ONGOING = 'ongoing';
export const APP_STATUS_ONGOING_TEXT = '進行中';
export const APP_STATUS_ONGOING_COLOR = '#0081a7';
export const APP_STATUS_COMPLETE = 'complete';
export const APP_STATUS_COMPLETE_TEXT = '応募完了';
export const APP_STATUS_COMPLETE_COLOR = '#52b788';
export const APP_STATUS_PASS = 'pass';
export const APP_STATUS_PASS_TEXT = '採用';
export const APP_STATUS_PASS_COLOR = '#06d6a0';
export const APP_STATUS_FAIL = 'fail';
export const APP_STATUS_FAIL_TEXT = '不採用';
export const APP_STATUS_FAIL_COLOR = '#ef476f';
export const APP_STATUS_DODGE = 'dodge';
export const APP_STATUS_DODGE_TEXT = '面接トビ';
export const APP_STATUS_DODGE_COLOR = '#fcbf49';
export const APP_STATUS_CANCEL = 'cancel';
export const APP_STATUS_CANCEL_TEXT = 'キャンセル';
export const APP_STATUS_CANCEL_COLOR = '#ff6b6b';
export const APP_STATUS_KEEP = 'keep';
export const APP_STATUS_KEEP_TEXT = '保留';
export const APP_STATUS_KEEP_COLOR = '#f77f00';
export const APP_STATUS_DONE = 'done';
export const APP_STATUS_DONE_TEXT = '完了';
export const APP_STATUS_DONE_COLOR = '#38b000';

export const APP_STATUS_ARRAY = DEEP_FREEZE([
	{ value: 'start', text: '新規', color: APP_STATUS_START_COLOR },
	{ value: 'ongoing', text: '進行中', color: APP_STATUS_ONGOING_COLOR },
	{ value: 'complete', text: '応募完了', color: APP_STATUS_COMPLETE_COLOR },
	{ value: 'pass', text: '採用', color: APP_STATUS_PASS_COLOR },
	{ value: 'fail', text: '不採用', color: APP_STATUS_FAIL_COLOR },
	{ value: 'dodge', text: '面接トビ', color: APP_STATUS_DODGE_COLOR },
	{ value: 'cancel', text: 'キャンセル', color: APP_STATUS_CANCEL_COLOR },
	{ value: 'keep', text: '保留', color: APP_STATUS_KEEP_COLOR },
	{ value: 'done', text: '完了', color: APP_STATUS_DONE_COLOR },
]);

export const PASS_SUB_STATUS_ENROLLED = 'passEmployed';
export const PASS_SUB_STATUS_ENROLLED_TEXT = '在籍中';
export const PASS_SUB_STATUS_LEAVE = 'passRest';
export const PASS_SUB_STATUS_LEAVE_TEXT = '休職中';
export const PASS_SUB_STATUS_TRIAL = 'trialComplete';
export const PASS_SUB_STATUS_TRIAL_TEXT = '体験入店';
export const PASS_SUB_STATUS_CLOSED = 'storeClosed';
export const PASS_SUB_STATUS_CLOSED_TEXT = '退店済み';

export const FAIL_SUB_STATUS_REJECTED = 'failRejected';
export const FAIL_SUB_STATUS_REJECTED_TEXT = '不採用判定';
export const FAIL_SUB_STATUS_DECLINED = 'failDeclined';
export const FAIL_SUB_STATUS_DECLINED_TEXT = '本人辞退';
export const FAIL_SUB_STATUS_UNSUCCESS = 'failNotPassed';
export const FAIL_SUB_STATUS_UNSUCCESS_TEXT = '勤務不成立';

export const KEEP_SUB_STATUS_APPLICANT = 'holdApplicant';
export const KEEP_SUB_STATUS_APPLICANT_TEXT = '本人保留';
export const KEEP_SUB_STATUS_STORE = 'holdStore';
export const KEEP_SUB_STATUS_STORE_TEXT = '店舗保留';

export const RECRUIT_STATUS_ARRAY = DEEP_FREEZE([
	{ value: 'passEmployed', text: '在籍中', color: APP_STATUS_PASS_COLOR },
	{ value: 'passRest', text: '休職中', color: APP_STATUS_PASS_COLOR },
	{ value: 'trialComplete', text: '体験入店', color: APP_STATUS_PASS_COLOR },
	{ value: 'storeClosed', text: '退店済み', color: APP_STATUS_PASS_COLOR },
	{ value: 'failRejected', text: '不採用判定', color: APP_STATUS_FAIL_COLOR },
	{ value: 'failDeclined', text: '本人辞退', color: APP_STATUS_FAIL_COLOR },
	{
		value: 'failNotPassed',
		text: '勤務不成立',
		color: APP_STATUS_FAIL_COLOR,
	},
	{ value: 'holdApplicant', text: '本人保留', color: APP_STATUS_KEEP_COLOR },
	{ value: 'holdStore', text: '店舗保留', color: APP_STATUS_KEEP_COLOR },
]);

export const isAppStatusStart = (status) => {
	return status === APP_STATUS_START;
};

export const isAppStatusOngoing = (status) => {
	return status === APP_STATUS_ONGOING;
};

export const isAppStatusComplete = (status) => {
	return status === APP_STATUS_COMPLETE;
};

export const isAppStatusPass = (status) => {
	return status === APP_STATUS_PASS;
};

export const isAppStatusFail = (status) => {
	return status === APP_STATUS_FAIL;
};

export const isAppStatusDodge = (status) => {
	return status === APP_STATUS_DODGE;
};

export const isAppStatusCancel = (status) => {
	return status === APP_STATUS_CANCEL;
};

export const isAppStatusKeep = (status) => {
	return status === APP_STATUS_KEEP;
};

export const isAppStatusDone = (status) => {
	return status === APP_STATUS_DONE;
};

export const getAppStatusBadge = (status) => {
	switch (status) {
		case APP_STATUS_START:
			return (
				<Badge
					color={APP_STATUS_START_COLOR}
					text={APP_STATUS_START_TEXT}
				/>
			);
		case APP_STATUS_ONGOING:
			return <Badge status="processing" text={APP_STATUS_ONGOING_TEXT} />;
		case APP_STATUS_COMPLETE:
			return (
				<Badge
					color={APP_STATUS_COMPLETE_COLOR}
					text={APP_STATUS_COMPLETE_TEXT}
				/>
			);
		case APP_STATUS_PASS:
			return (
				<Badge
					color={APP_STATUS_PASS_COLOR}
					text={APP_STATUS_PASS_TEXT}
				/>
			);
		case APP_STATUS_FAIL:
			return (
				<Badge
					color={APP_STATUS_FAIL_COLOR}
					text={APP_STATUS_FAIL_TEXT}
				/>
			);
		case APP_STATUS_DODGE:
			return (
				<Badge
					color={APP_STATUS_DODGE_COLOR}
					text={APP_STATUS_DODGE_TEXT}
				/>
			);
		case APP_STATUS_CANCEL:
			return (
				<Badge
					color={APP_STATUS_CANCEL_COLOR}
					text={APP_STATUS_CANCEL_TEXT}
				/>
			);
		case APP_STATUS_KEEP:
			return (
				<Badge
					color={APP_STATUS_KEEP_COLOR}
					text={APP_STATUS_KEEP_TEXT}
				/>
			);
		case APP_STATUS_DONE:
			return (
				<Badge
					color={APP_STATUS_DONE_COLOR}
					text={APP_STATUS_DONE_TEXT}
				/>
			);
		default:
			break;
	}
};

export const getAppStatusText = (status) => {
	switch (status) {
		case APP_STATUS_START:
			return APP_STATUS_START_TEXT;
		case APP_STATUS_ONGOING:
			return APP_STATUS_ONGOING_TEXT;
		case APP_STATUS_COMPLETE:
			return APP_STATUS_COMPLETE_TEXT;
		case APP_STATUS_PASS:
			return APP_STATUS_PASS_TEXT;
		case APP_STATUS_FAIL:
			return APP_STATUS_FAIL_TEXT;
		case APP_STATUS_DODGE:
			return APP_STATUS_DODGE_TEXT;
		case APP_STATUS_CANCEL:
			return APP_STATUS_CANCEL_TEXT;
		case APP_STATUS_KEEP:
			return APP_STATUS_KEEP_TEXT;
		case APP_STATUS_DONE:
			return APP_STATUS_DONE_TEXT;
		default:
			break;
	}
};

export const getAppStatusColor = (status) => {
	switch (status) {
		case APP_STATUS_START:
			return APP_STATUS_START_COLOR;
		case APP_STATUS_ONGOING:
			return APP_STATUS_ONGOING_COLOR;
		case APP_STATUS_COMPLETE:
			return APP_STATUS_COMPLETE_COLOR;
		case APP_STATUS_PASS:
			return APP_STATUS_PASS_COLOR;
		case APP_STATUS_FAIL:
			return APP_STATUS_FAIL_COLOR;
		case APP_STATUS_DODGE:
			return APP_STATUS_DODGE_COLOR;
		case APP_STATUS_CANCEL:
			return APP_STATUS_CANCEL_COLOR;
		case APP_STATUS_KEEP:
			return APP_STATUS_KEEP_COLOR;
		case APP_STATUS_DONE:
			return APP_STATUS_DONE_COLOR;
		default:
			break;
	}
};

export const GET_STATUS_COLOR_PASS = (value) => {
	switch (value) {
		case 'resumeRM':
			return APP_STATUS_PASS_COLOR;
		case 'adminConfirmRM':
			return APP_STATUS_COMPLETE_COLOR;
		default:
			return APP_STATUS_PASS_COLOR;
	}
};

export const getRecruitStatusColor = (status) => {
	switch (status) {
		case PASS_SUB_STATUS_ENROLLED:
			return APP_STATUS_PASS_COLOR;
		case PASS_SUB_STATUS_LEAVE:
			return APP_STATUS_PASS_COLOR;
		case PASS_SUB_STATUS_TRIAL:
			return APP_STATUS_PASS_COLOR;
		case PASS_SUB_STATUS_CLOSED:
			return APP_STATUS_PASS_COLOR;
		case FAIL_SUB_STATUS_REJECTED:
			return APP_STATUS_FAIL_COLOR;
		case FAIL_SUB_STATUS_DECLINED:
			return APP_STATUS_FAIL_COLOR;
		case FAIL_SUB_STATUS_UNSUCCESS:
			return APP_STATUS_FAIL_COLOR;
		case KEEP_SUB_STATUS_APPLICANT:
			return APP_STATUS_KEEP_COLOR;
		case KEEP_SUB_STATUS_STORE:
			return APP_STATUS_KEEP_COLOR;
		default:
			break;
	}
};

export const GET_SUB_STATUS_LABEL = (value) => {
	switch (value) {
		case 'passEmployed':
			return '在籍中';
		case 'passRest':
			return '休職中';
		case 'trialComplete':
			return '体験入店';
		case 'storeClosed':
			return '退店済み';
		case 'failRejected':
			return '不採用判定';
		case 'failDeclined':
			return '本人辞退';
		case 'failNotPassed':
			return '勤務不成立';
		case 'holdApplicant':
			return '本人保留';
		case 'holdStore':
			return '店舗保留';
		default:
			break;
	}
};

export const GET_STATUS_PASS = (value) => {
	switch (value) {
		case 'resumeRM':
			return '在籍中';
		case 'adminConfirmRM':
			return '採用確認';
		default:
			return '在籍中';
	}
};

/* RESUME STATUS */
export const RESUME_NEW = 'new';
export const RESUME_SUBMIT = 'submit';
export const RESUME_COMPLETE = 'complete';

/* IDENTITY STATUS */
export const IDENTITY_NEW = 'new';
export const IDENTITY_SUBMIT = 'submit';
export const IDENTITY_COMPLETE = 'complete';

/* KOZA STATUS */
export const KOZA_NEW = 'new';
export const KOZA_SUBMIT = 'submit';
export const KOZA_COMPLETE = 'complete';

export const GENDER_OPTIONS = DEEP_FREEZE([
	{ label: '男', value: 'male' },
	{ label: '女', value: 'female' },
]);

export const ACCOUNT_TYPE_OPTIONS = DEEP_FREEZE([
	{ label: '普通', value: 'normal' },
	{ label: '当座', value: 'temporary' },
	{ label: '貯蓄', value: 'savings' },
]);

export const DOCUMENT_TYPE_OPTIONS = DEEP_FREEZE([
	{ label: '運転免許証', value: 'drivingLicense' },
	{ label: 'パスポート', value: 'passport' },
	{ label: '住民票', value: 'residentCard' },
	{ label: '住基カード', value: 'jukiCard' },
	{ label: 'マイナンバー', value: 'myNumber' },
	{ label: 'その他', value: 'others' },
]);

export const DOCUMENT_TYPE_OTHERS = 'others';

/* NOTIFY MESSAGE TYPE */
export const NOTIFY_TYPE_TEXT = 'text';
export const NOTIFY_TYPE_SLIDESHOW = 'slideshow';
export const NOTIFY_TYPES = DEEP_FREEZE([
	{ label: 'テキストメッセージ', value: NOTIFY_TYPE_TEXT },
	{ label: '画像メッセージ', value: NOTIFY_TYPE_SLIDESHOW },
]);

export const GET_GENDER_LABEL = (value) => {
	return value === 'male' ? '男' : '女';
};

export const GET_ACCOUNT_TYPE_LABEL = (value) => {
	switch (value) {
		case 'normal':
			return '普通';
		case 'temporary':
			return '当座';
		case 'savings':
			return '貯蓄';
		default:
			break;
	}
};

export const GET_ACCOUNT_TYPE_VALUE = (label) => {
	switch (label) {
		case '普通':
			return 'normal';
		case '当座':
			return 'temporary';
		case '貯蓄':
			return 'savings';
		default:
			break;
	}
};

export const GET_DOCUMENT_TYPE_LABEL = (value) => {
	switch (value) {
		case 'drivingLicense':
			return '運転免許証';
		case 'passport':
			return 'パスポート';
		case 'residentCard':
			return '住民票';
		case 'jukiCard':
			return '住基カード';
		case 'myNumber':
			return 'マイナンバー';
		case 'others':
			return 'その他';
		default:
			break;
	}
};

export const GET_DOCUMENT_TYPE_VALUE = (label) => {
	switch (label) {
		case '運転免許証':
			return 'drivingLicense';
		case 'パスポート':
			return 'passport';
		case '住民票':
			return 'residentCard';
		case '住基カード':
			return 'jukiCard';
		case 'マイナンバー':
			return 'myNumber';
		case 'その他':
			return 'others';
		default:
			break;
	}
};

/* STATUS ACTION */
export const APPROVE = 'approve';
export const APPROVE_TEXT = '確認済み';
export const APPROVE_WAITING_TEXT = '確認中';
export const REJECT = 'reject';
export const REJECT_TEXT = '不採用';

export const PASS_TEXT = '採用';
export const FAIL_TEXT = '不採用';

/* MESSAGE TYPE */
export const MESSAGE_TYPE_CALL = 'call';
export const MESSAGE_TYPE_BELL = 'bell';
export const MESSAGE_TYPE_TEXT = 'text';
export const MESSAGE_TYPE_IMAGE = 'image';
export const MESSAGE_TYPE_VIDEO = 'video';
export const MESSAGE_TYPE_SLIDESHOW = 'slideshow';

export const CALL_TITLE = '電話リクエスト';
export const CALL_MSG = `${CALL_TITLE}を送りました`;

/* CALL TYPE */
export const CALL_TYPE_LINE = 'line';
export const CALL_TYPE_LINE_TEXT = 'LINE電話リクエスト';
export const CALL_TYPE_LINK = 'link';
export const CALL_TYPE_LINK_TEXT = 'リンク';

/* LINE MODE */
export const LINE_MODE_BOT = 'bot';
export const LINE_MODE_CHAT = 'chat';

export const GET_AUDIENCE_STATUS = (value) => {
	switch (value) {
		case 'IN_PROGRESS':
			return (
				<Tag color="#fcbf49" className="mr-0">
					作成中
				</Tag>
			);
		case 'READY':
			return (
				<Tag color="#06d6a0" className="mr-0">
					完成
				</Tag>
			);
		case 'FAILED':
			return (
				<Tag color="#ff6b6b" className="mr-0">
					エラー
				</Tag>
			);
		case 'EXPIRED':
			return (
				<Tag color="#f77f00" className="mr-0">
					期限切れ
				</Tag>
			);
		case 'INACTIVE':
			return (
				<Tag color="#38a3a5" className="mr-0">
					無効
				</Tag>
			);
		case 'ACTIVATING':
			return (
				<Tag color="#52b788" className="mr-0">
					有効化中
				</Tag>
			);
		default:
			break;
	}
};

export const isResumeStatusNew = (status) => {
	return status === RESUME_NEW;
};

export const isResumeStatusSubmit = (status) => {
	return status === RESUME_SUBMIT;
};

export const isResumeStatusComplete = (status) => {
	return status === RESUME_COMPLETE;
};

export const isIdentityStatusNew = (status) => {
	return status === IDENTITY_NEW;
};

export const isIdentityStatusSubmit = (status) => {
	return status === IDENTITY_SUBMIT;
};

export const isIdentityStatusComplete = (status) => {
	return status === IDENTITY_COMPLETE;
};

export const isKozaStatusNew = (status) => {
	return status === KOZA_NEW;
};

export const isKozaStatusSubmit = (status) => {
	return status === KOZA_SUBMIT;
};

export const isKozaStatusComplete = (status) => {
	return status === KOZA_COMPLETE;
};

export const PREFECTURES = DEEP_FREEZE([
	{ value: 1, label: '北海道' },
	{ value: 2, label: '青森県' },
	{ value: 3, label: '岩手県' },
	{ value: 4, label: '宮城県' },
	{ value: 5, label: '秋田県' },
	{ value: 6, label: '山形県' },
	{ value: 7, label: '福島県' },
	{ value: 8, label: '茨城県' },
	{ value: 9, label: '栃木県' },
	{ value: 10, label: '群馬県' },
	{ value: 11, label: '埼玉県' },
	{ value: 12, label: '千葉県' },
	{ value: 13, label: '東京都' },
	{ value: 14, label: '神奈川県' },
	{ value: 15, label: '新潟県' },
	{ value: 16, label: '富山県' },
	{ value: 17, label: '石川県' },
	{ value: 18, label: '福井県' },
	{ value: 19, label: '山梨県' },
	{ value: 20, label: '長野県' },
	{ value: 21, label: '岐阜県' },
	{ value: 22, label: '静岡県' },
	{ value: 23, label: '愛知県' },
	{ value: 24, label: '三重県' },
	{ value: 25, label: '滋賀県' },
	{ value: 26, label: '京都府' },
	{ value: 27, label: '大阪府' },
	{ value: 28, label: '兵庫県' },
	{ value: 29, label: '奈良県' },
	{ value: 30, label: '和歌山県' },
	{ value: 31, label: '鳥取県' },
	{ value: 32, label: '島根県' },
	{ value: 33, label: '岡山県' },
	{ value: 34, label: '広島県' },
	{ value: 35, label: '山口県' },
	{ value: 36, label: '徳島県' },
	{ value: 37, label: '香川県' },
	{ value: 38, label: '愛媛県' },
	{ value: 39, label: '高知県' },
	{ value: 40, label: '福岡県' },
	{ value: 41, label: '佐賀県' },
	{ value: 42, label: '長崎県' },
	{ value: 43, label: '熊本県' },
	{ value: 44, label: '大分県' },
	{ value: 45, label: '宮崎県' },
	{ value: 46, label: '鹿児島県' },
	{ value: 47, label: '沖縄県' },
]);

const getSessionStorageOrDefault = (key, defaultValue) => {
	const stored = sessionStorage.getItem(key);
	if (!stored) {
		return defaultValue;
	}
	return JSON.parse(stored);
};

export const useSessionStorage = (key, defaultValue) => {
	const [value, setValue] = useState(
		getSessionStorageOrDefault(key, defaultValue),
	);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};

const getLocalStorageOrDefault = (key, defaultValue) => {
	const stored = localStorage.getItem(key);
	if (!stored) {
		return defaultValue;
	}
	return JSON.parse(stored);
};

export const useLocalStorage = (key, defaultValue) => {
	const [value, setValue] = useState(
		getLocalStorageOrDefault(key, defaultValue),
	);

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};

/* CHECK MOUNTED REACT */
export function USE_IS_MOUNTED_REF() {
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => (isMountedRef.current = false);
	});

	return isMountedRef;
}

export const ARRAY_RANGE_CREATOR = (start, end, step) => {
	return Array(Math.ceil((end - start) / step))
		.fill(start)
		.map((x, y) => x + y * step);
};

export function GET_BASE_64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export function COLOR_ADJUST(color, amount) {
	const colors = generate(color);

	return colors[amount - 100] || colors[0];
}

export const RE_ORDER = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const normFile = (e) => {
	if (Array.isArray(e)) {
		return e;
	}

	return e?.fileList;
};

export const RESIZE_FILE = (file, type) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			1024,
			1024,
			'JPEG',
			80,
			0,
			(uri) => {
				resolve(uri);
			},
			type,
		);
	});

export const GET_AUTH = (auth) => {
	switch (auth) {
		case 'master':
			return 'マスター';
		case 'manager':
			return 'マネージャー';
		default:
			return '';
	}
};

export function REMOVE_DASH_CHARACTER(str) {
	return str.replace('-', '');
}

export const ANIMATION_VARIANT_STAGGER_CONTAINER = {
	hidden: {
		transition: {
			staggerChildren: 0.01,
			staggerDirection: -1,
			when: 'afterChildren',
		},
	},
	show: {
		transition: {
			staggerChildren: 0.04,
			staggerDirection: 1,
			when: 'beforeChildren',
		},
	},
};

export const ANIMATION_VARIANT_STAGGER_ITEM = {
	hidden: {
		opacity: 0,
		scale: 0.8,
		transition: { type: 'spring', bounce: 0.4 },
	},
	show: {
		opacity: 1,
		scale: 1,
		transition: { type: 'spring', bounce: 0.4 },
	},
};

/* FORM SCROLL CONFIG */
export const FORM_SCROLL_CONFIG = {
	behavior: 'smooth',
	block: 'top',
	inline: 'center',
};

export const convertStringToObject = (str) => {
	// Remove any leading or trailing spaces from the string
	str = str.trim();

	// Split the string by comma
	var namesArray = str.split(',');

	// Trim each name and create an object with names as keys and values set to true
	var namesObject = {};
	namesArray.forEach(function (name) {
		name = name.trim();
		if (name !== '') {
			namesObject[name] = true;
		}
	});
	return namesObject;
};
