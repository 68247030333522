import { useEffect, useState } from 'react'
import * as COMMONS from 'common/common'
import * as API from 'common/api'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useQuery } from '@tanstack/react-query'

export default function TopProgressBar() {
	const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()

	const [publicSettings, setPublicSettings] = useState({
		PRIMARY_COLOR: COMMONS.PRIMARY_COLOR,
		PRIMARY_LIGHT_COLOR: COMMONS.PRIMARY_LIGHT_COLOR
	})

	useQuery([API.QUERY_KEY_GET_PUBLIC_SETTINGS], API.GET_PUBLIC_SETTINGS, {
		onSuccess: (response) => {
			if (isMountedRef.current) {
				if (response?.data) {
					setPublicSettings({
						PRIMARY_COLOR: response.data[API.SETTINGS_KEY_SYSTEM_COLOR]
							? response.data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
							: COMMONS.PRIMARY_COLOR,
						PRIMARY_LIGHT_COLOR: response.data[API.SETTINGS_KEY_SYSTEM_COLOR]
							? COMMONS.COLOR_ADJUST(response.data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString, 100)
							: COMMONS.PRIMARY_LIGHT_COLOR
					})
				}
			}
		}
	})

	useEffect(() => {
		NProgress.configure({ showSpinner: false })

		const style = document.createElement('style')
		style.textContent = `
    #nprogress .bar {
      background: ${publicSettings?.PRIMARY_COLOR};
    }

    #nprogress .peg {
      box-shadow: 0 0 10px ${publicSettings?.PRIMARY_COLOR}, 0 0 5px ${publicSettings?.PRIMARY_COLOR};
    }
    `
		document.body.appendChild(style)

		NProgress.start()

		return () => {
			NProgress.done()
		}
	})

	return ''
}
