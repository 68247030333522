import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './Router'
import 'antd/dist/reset.css'
import './index.css'

export default class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		)
	}
}
