import { useReducer, useContext, createContext } from "react";

const CustomStateContext = createContext();
const CustomDispatchContext = createContext();

const initialState = {
  question_list: [],
  social_network: [],
  reason_apply: [],
  wish: [],
  name_kanji: "",
  name_kana: "",
  date_of_birth: "",
  gender: "男性",
  post_code: "",
  address: "",
};

export const actions = {
  SET_QUESTION_LIST: "SET_QUESTION_LIST",
  SET_RECRUITMENT_ONE: "SET_RECRUITMENT_ONE",
  SET_RECRUITMENT_TWO: "SET_RECRUITMENT_TWO",
  SET_RECRUITMENT_THREE: "SET_RECRUITMENT_THREE",
  SET_RECRUITMENT_FIVE: "SET_RECRUITMENT_FIVE",
  SET_RECRUITMENT_SIX: "SET_RECRUITMENT_SIX",
  SET_RECRUITMENT_SEVEN: "SET_RECRUITMENT_SEVEN",
  SET_RECRUITMENT_EIGHT: "SET_RECRUITMENT_EIGHT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_QUESTION_LIST:
      return {
        ...state,
        question_list: action.question_list,
      };
    case actions.SET_RECRUITMENT_ONE:
      return {
        ...state,
        social_network: action.social_network,
      };
    case actions.SET_RECRUITMENT_TWO:
      return {
        ...state,
        reason_apply: action.reason_apply,
      };
    case actions.SET_RECRUITMENT_THREE:
      return {
        ...state,
        wish: action.wish,
      };
    case actions.SET_RECRUITMENT_FIVE:
      return {
        ...state,
        name_kanji: action.name_kanji,
        name_kana: action.name_kana,
      };
    case actions.SET_RECRUITMENT_SIX:
      return {
        ...state,
        date_of_birth: action.date_of_birth,
      };
    case actions.SET_RECRUITMENT_SEVEN:
      return {
        ...state,
        gender: action.gender,
      };
    case actions.SET_RECRUITMENT_EIGHT:
      return {
        ...state,
        post_code: action.post_code,
        address: action.address,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const CustomProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CustomDispatchContext.Provider value={dispatch}>
      <CustomStateContext.Provider value={state}>
        {children}
      </CustomStateContext.Provider>
    </CustomDispatchContext.Provider>
  );
};

export const useCustomProvider = () => useContext(CustomStateContext);
export const useCustomDispatch = () => useContext(CustomDispatchContext);
