import axios from 'axios';

/* BASE URL */
export const SITE_URL = process.env.REACT_APP_API_URL;

export const BASE_URL = SITE_URL + '/api';
export const UPLOADS_URL = SITE_URL + '/uploads/';
export const MEDIA_URL = SITE_URL + '/imageuploads/';
export const VIDEO_URL = SITE_URL + '/videouploads/';
export const RESUME_URL = SITE_URL + '/api/m/files/';
export const ZIP_URL = 'https://yubinbango.github.io/yubinbango-data/data';
export const PUBLIC_HOLIDAY_URL =
	'https://holidays-jp.github.io/api/v1/date.json';

/* BACKEND API URLS */
export const API_CHECK_SESSION = BASE_URL + '/sess';
export const API_LOGIN = BASE_URL + '/login';
export const API_LOGOUT = BASE_URL + '/logout';
export const API_AUTH = BASE_URL + '/m/auth';
export const API_STORES = BASE_URL + '/m/stores';
export const API_QUESTIONS = BASE_URL + '/m/questions';
export const API_MANAGERS = BASE_URL + '/m/managers';
export const API_SETTINGS = BASE_URL + '/m/settings';
export const API_NOTIFICATIONS = BASE_URL + '/m/settings';
export const API_LIFF = BASE_URL + '/liff';

/* SOCKET PATH */
export const SOCKET_PATH = '/socket.io';

/* SOCKET KEY */
export const SOCKET_KEY_NEW_SYSTEM_SETTING = 'newSystemSetting';
export const SOCKET_KEY_UPDATE_SYSTEM_SETTING = 'updateSystemSetting';
export const SOCKET_KEY_DELETE_SYSTEM_SETTING = 'deleteSystemSetting';
export const SOCKET_KEY_UPDATE_FAVICON = 'updateFavicon';
export const SOCKET_KEY_UPDATE_LOGO = 'updateLogo';
export const SOCKET_KEY_NEW_STORE = 'newStore';
export const SOCKET_KEY_UPDATE_STORE = 'updateStore';
export const SOCKET_KEY_NEW_TEMPLATE = 'newTemplate';
export const SOCKET_KEY_UPDATE_TEMPLATE = 'updateTemplate';
export const SOCKET_KEY_DELETE_TEMPLATE = 'deleteTemplate';
export const SOCKET_KEY_NEW_CHAT = 'newChat';
export const SOCKET_KEY_NEW_APPLICATION = 'newApplication';
export const SOCKET_KEY_UPDATE_APPLICATION = 'updateApplication';
export const SOCKET_KEY_DELETE_APPLICATION = 'deleteApplication';
export const SOCKET_KEY_UPDATE_QUESTIONS = 'updateQuestions';
export const SOCKET_KEY_UPDATE_AUDIENCE = 'updateAudience';
export const SOCKET_KEY_NEW_APPOINTMENT = 'newAppointment';
export const SOCKET_KEY_UPDATE_APPOINTMENT = 'updateAppointment';
export const SOCKET_KEY_DELETE_APPOINTMENT = 'deleteAppointment';
export const SOCKET_KEY_NEW_SUBSCRIBER = 'newSubscriber';
export const SOCKET_KEY_DELETE_SUBSCRIBER = 'deleteSubscriber';
export const SOCKET_KEY_NEW_STORE_SETTING = 'newStoreSetting';
export const SOCKET_KEY_UPDATE_STORE_SETTING = 'updateStoreSetting';
export const SOCKET_KEY_DELETE_STORE_SETTING = 'deleteStoreSetting';

/* QUERY KEY */
export const QUERY_KEY_CHECK_SESSION = 'checkSession';
export const QUERY_KEY_GET_AUTH = 'getAuth';
export const QUERY_KEY_GET_LOGO = 'getLogo';
export const QUERY_KEY_GET_FAVICON = 'getFavicon';
export const QUERY_KEY_GET_STORES = 'getStores';
export const QUERY_KEY_GET_STORE = 'getStore';
export const QUERY_KEY_GET_QUESTIONS = 'getQuestions';
export const QUERY_KEY_GET_QUESTION_TYPES = 'getQuestionTypes';
export const QUERY_KEY_GET_APPOINTMENTS = 'getAppointments';
export const QUERY_KEY_GET_APPLICANTS = 'getApplicants';
export const QUERY_KEY_GET_APPLICANTS_LIST = 'getApplicantsList';
export const QUERY_KEY_GET_MANAGERS = 'getManagers';
export const QUERY_KEY_GET_APPLICATION = 'getApplication';
export const QUERY_KEY_GET_APPLICATION_QUESTIONS = 'getApplicationQuestions';
export const QUERY_KEY_GET_AVAILABLE_APPOINTMENTS = 'getAvailableAppointments';
export const QUERY_KEY_GET_CHATS = 'getChats';
export const QUERY_KEY_GET_PUBLIC_SETTINGS = 'getPublicSettings';
export const QUERY_KEY_GET_POTENTIAL_APPLICANTS = 'getPotentialApplicants';
export const QUERY_KEY_GET_ADMINS = 'getAdmins';
export const QUERY_KEY_GET_REPLY_TEMPLATES = 'getReplyTemplates';
export const QUERY_KEY_GET_STORE_SETTINGS = 'getStoreSettings';
export const QUERY_KEY_GET_CAROUSELS = 'getCarousels';
export const QUERY_KEY_GET_RICHMENUS = 'getRichmenus';
export const QUERY_KEY_GET_RESUME_SETTINGS = 'getResumeSettings';
export const QUERY_KEY_GET_AUDIENCES = 'getAudiences';
export const QUERY_KEY_ADMIN_CHATS = 'ADMIN_CHATS';
export const QUERY_KEY_PUBLIC_HOLIDAYS = 'PUBLIC_HOLIDAYS';
export const QUERY_KEY_PUBLIC_NOTIFICATIONS = 'notifications';
export const QUERY_KEY_GET_QUESTIONS_LIST = 'getQuestionsList';
export const QUERY_KEY_REFILL = 'refill';
export const QUERY_KEY_GET_LOCATIONLIST = 'getLocationList';

/* SETTINGS KEY */
export const SETTINGS_KEY_SYSTEM_COLOR = 'systemColor';
export const SETTINGS_KEY_SYSTEM_TITLE = 'systemTitle';

/* SETTINGS LABEL */
export const SETTINGS_LABEL_SYSTEM_COLOR = 'システム色';
export const SETTINGS_LABEL_SYSTEM_TITLE = 'システムタイトル';

/* AXIOS CONFIG */
const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: BASE_URL,
});

export async function ADMIN_LOGIN(data) {
	return await axiosInstance.post(API_LOGIN, data);
}

export async function ADMIN_LOGOUT() {
	return await axiosInstance.get(API_LOGOUT);
}

export async function ADMIN_CHECK_SESSION() {
	return await axiosInstance.get(API_CHECK_SESSION);
}

export async function ADMIN_GET_AUTH() {
	return await axiosInstance.get(API_AUTH);
}

export async function ADMIN_GET_STORES() {
	return await axiosInstance.get(API_STORES);
}

export async function ADMIN_GET_STORE(storeId) {
	return await axiosInstance.get(API_STORES + '/' + storeId);
}

export async function ADMIN_GET_QUESTIONS(storeId) {
	return await axiosInstance.get(API_STORES + '/' + storeId + '/questions');
}

export async function ADMIN_UPDATE_STORE(data) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('name', data.name);
	formData.append('picUrl', data.picUrl);
	formData.append('remarks', data.remarks);

	return await axiosInstance.put(
		API_STORES + '/' + data.storeId,
		formData,
		headerData,
	);
}

export async function ADMIN_GET_QUESTION_TYPES() {
	return await axiosInstance.get(API_QUESTIONS + '/types');
}

export async function ADMIN_CREATE_QUESTION(data) {
	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/questions',
		data,
	);
}

export async function ADMIN_UPDATE_QUESTION(data) {
	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/questions/' + data.questionId,
		data,
	);
}

export async function ADMIN_DELETE_QUESTION(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/questions',
		data,
	);
}

export async function ADMIN_UPDATE_QUESTION_ORDER(data) {
	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/questions/order',
		data.questionOrders,
	);
}

export async function ADMIN_GET_APPOINTMENTS(
	storeId,
	calendarFrom,
	calendarTo,
	locationId,
) {
	return await axiosInstance.get(
		API_STORES +
			'/' +
			storeId +
			'/locations/' +
			locationId +
			'/appointments',
		{
			params: { from: calendarFrom, to: calendarTo },
		},
	);
}

export async function ADMIN_GET_LOCATIONS(storeId) {
	return await axiosInstance.get(API_STORES + '/' + storeId + '/locations');
}

export async function ADMIN_GET_APPLICANTS(
	storeId,
	paginationPerPage,
	paginationPage,
	paginationSort,
	paginationSortKey,
	filterResumeId,
	filterName,
	filterTelephone,
	filterAddress,
	filterAgeMin,
	filterAgeMax,
	filterStatus,
	filterSubStatus,
	filterResumeDate,
	filterLocationId,
	// filterIsHide,
) {
	const paramData = {
		params: {
			pp: paginationPerPage,
			p: paginationPage,
			sort: paginationSort,
			sortKey: paginationSortKey,
			resumeId: filterResumeId,
			fullName: filterName,
			telephone: filterTelephone,
			address: filterAddress,
			dateOfBirthMin: filterAgeMin,
			dateOfBirthMax: filterAgeMax,
			status: filterStatus,
			subStatus: filterSubStatus,
			locationId: filterLocationId,
			...filterResumeDate,
			// isHide: filterIsHide,
		},
	};

	return await axiosInstance.get(
		API_STORES + '/' + storeId + '/applicants',
		paramData,
	);
}

export async function ADMIN_GET_APPLICANTS_LIST(storeId) {
	return await axiosInstance.get(
		API_STORES + '/' + storeId + '/applicants/list',
	);
}

export async function ADMIN_DELETE_APPLICATION(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/applications/' + data.applicationId,
	);
}

export async function ADMIN_CREATE_SCHEDULE(data) {
	return await axiosInstance.post(
		API_STORES +
			'/' +
			data.storeId +
			'/locations/' +
			data.locationId +
			'/appointments',
		data,
	);
}

export async function ADMIN_CONFIRM_HANDLE(data) {
	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/questions/' +
			data.questionId,
		data,
	);
}

export async function ADMIN_UPDATE_APPLICATION_PROPS(data) {
	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/props',
		data,
	);
}

export async function ADMIN_DELETE_UPLOAD_HANDLE(data) {
	const param = {
		data: {
			answerImageId: data.answerImageId,
		},
	};

	return await axiosInstance.delete(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/questions/' +
			data.questionId +
			'/answers/' +
			data.answerId,
		param,
	);
}

export async function ADMIN_HANDLE_UPLOAD(data) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	data.picUrl.forEach((image) => formData.append('picUrl', image));

	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/questions/' +
			data.questionId,
		formData,
		headerData,
	);
}

export async function ADMIN_RESUME_START(data) {
	return await axiosInstance.post(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/resume',
		data,
	);
}

export async function ADMIN_INTERVIEW_START(data) {
	return await axiosInstance.post(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/confirm',
		data,
	);
}

export async function ADMIN_RESUME_UPDATE_HANDLE(data) {
	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/resume',
		data,
	);
}

export async function ADMIN_RESUME_DOWNLOAD(data) {
	return await axiosInstance.get(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/resume',
		{ responseType: 'blob' },
	);
}

export async function ADMIN_DOWNLOAD_APPLICANTS(data) {
	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/applicants/csv',
		data,
	);
}

export async function ADMIN_CREATE_APPOINTMENT(data) {
	const paramData = {
		applicantId: data.applicantId,
		questionId: data.questionId,
		remarks: data.remarks,
	};

	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/locations/' +
			data.locationId +
			'/appointments/' +
			data.appointmentId,
		paramData,
	);
}

export async function ADMIN_CANCEL_SCHEDULE(data) {
	return await axiosInstance.put(
		API_STORES +
			'/' +
			data.storeId +
			'/locations/' +
			data.locationId +
			'/appointments/' +
			data.appointmentId,
	);
}

export async function ADMIN_DELETE_SCHEDULE(data) {
	return await axiosInstance.delete(
		API_STORES +
			'/' +
			data.storeId +
			'/locations/' +
			data.locationId +
			'/appointments/' +
			data.appointmentId,
	);
}

export async function ADMIN_GET_MANAGERS() {
	return await axiosInstance.get(API_MANAGERS);
}

export async function ADMIN_CREATE_MANAGER(data) {
	return await axiosInstance.post(API_MANAGERS, data);
}

export async function ADMIN_UPDATE_MANAGER(data) {
	return await axiosInstance.put(API_MANAGERS + '/' + data.managerId, data);
}

export async function ADMIN_DELETE_MANAGER(data) {
	return await axiosInstance.delete(API_MANAGERS + '/' + data.managerId);
}

export async function ADMIN_GET_CHATS(storeId, applicationId) {
	return await axiosInstance.get(
		API_STORES + '/' + storeId + '/applications/' + applicationId + '/chat',
	);
}

export async function ADMIN_SEND_CHAT(data) {
	return await axiosInstance.post(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/chat',
		data,
	);
}

export async function ADMIN_SEND_CALL_REQUEST(data) {
	return await axiosInstance.post(
		API_STORES +
			'/' +
			data.storeId +
			'/applications/' +
			data.applicationId +
			'/call',
	);
}

export async function ADMIN_GET_AUDIENCES(storeId) {
	return await axiosInstance.get(API_STORES + '/' + storeId + '/audiences');
}

export async function ADMIN_CREATE_AUDIENCE(data) {
	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/audiences',
		data,
	);
}

export async function ADMIN_DELETE_AUDIENCE(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/audiences/' + data.audienceGroupId,
	);
}

export async function ADMIN_SEARCH_AUDIENCE_APPLICANTS(data) {
	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/audiences/find',
		data,
	);
}

export async function ADMIN_GET_POTENTIAL_APPLICANTS(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/subscribers/list',
	);
}

export async function ADMIN_GET_NOTIFIERS(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/subscribers',
	);
}

export async function ADMIN_REGISTER_NOTIFIER(data) {
	const paramData = {
		applicantId: data.applicantId,
	};

	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/subscribers',
		paramData,
	);
}

export async function ADMIN_REMOVE_NOTIFIER(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/subscribers/' + data.subscriberId,
	);
}

export async function ADMIN_GET_REPLY_TEMPLATES(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/templates',
	);
}

export async function ADMIN_CREATE_REPLY_TEMPLATE(data) {
	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/templates',
		data,
	);
}

export async function ADMIN_UPDATE_REPLY_TEMPLATE(data) {
	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/templates/' + data.templateId,
		data,
	);
}

export async function ADMIN_DELETE_REPLY_TEMPLATE(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/templates/' + data.templateId,
	);
}

export async function ADMIN_GET_STORE_SETTINGS(data) {
	return await axiosInstance.get(API_STORES + '/' + data.storeId);
}

export async function ADMIN_UPDATE_STORE_SETTINGS(data) {
	const paramData = {
		callType: data.callType,
		callLink: data.callLink,
		callRemindTime: data.callRemindTime,
		callRemindText: data.callRemindText,
		msgRemindTime: data.msgRemindTime,
		msgRemindText: data.msgRemindText,
	};

	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/settings',
		paramData,
	);
}

export async function ADMIN_GET_CAROUSELS(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/slideshows',
	);
}

export async function ADMIN_CREATE_CAROUSEL(data) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	for (let key in data) {
		formData.append(key, data[key]);
	}

	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/slideshows',
		formData,
		headerData,
	);
}

export async function ADMIN_SEND_CAROUSEL(data) {
	const paramData = {
		applicationId: data.applicationId,
	};

	return await axiosInstance.post(
		API_STORES + '/' + data.storeId + '/slideshows/' + data.carouselId,
		paramData,
	);
}

export async function ADMIN_DELETE_CAROUSEL(data) {
	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/slideshows/' + data.carouselId,
	);
}

export async function ADMIN_GET_RICHMENUS(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/richmenus',
	);
}

export async function ADMIN_UPDATE_RICHMENUS(data) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('type', data.type);
	formData.append('picUrl', data.picUrl);

	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/richmenus',
		formData,
		headerData,
	);
}

export async function ADMIN_DELETE_RICHMENU(data) {
	const param = {
		data: {
			type: data.type,
		},
	};

	return await axiosInstance.delete(
		API_STORES + '/' + data.storeId + '/richmenus',
		param,
	);
}

export async function ADMIN_GET_RESUME_SETTINGS(data) {
	return await axiosInstance.get(
		API_STORES + '/' + data.storeId + '/settings/resume',
	);
}

export async function ADMIN_UPDATE_RESUME_SETTINGS(data) {
	const paramData = {
		workPosition: data.workPosition,
		workDescription: data.workDescription,
	};

	return await axiosInstance.put(
		API_STORES + '/' + data.storeId + '/settings/resume',
		paramData,
	);
}

export async function CLIENT_CHECK_STORE(data) {
	const headerData = {
		headers: { 'access-token': data.accessToken },
	};
	const paramData = {
		liffId: data.liffId,
	};

	return await axiosInstance.post(API_LIFF + '/store', paramData, headerData);
}

export async function CLIENT_GET_APPLICATION(storeId, accessToken) {
	const headerData = {
		headers: { 'access-token': accessToken },
	};

	return await axiosInstance.get(
		API_LIFF + '/store/' + storeId + '/apply',
		headerData,
	);
}

export async function CLIENT_GET_APPLICATION_QUESTIONS(storeId, accessToken) {
	const headerData = {
		headers: { 'access-token': accessToken },
	};

	return await axiosInstance.get(
		API_LIFF + '/store/' + storeId + '/questions',
		headerData,
	);
}

export async function CLIENT_GET_QUESTION_TYPES(accessToken) {
	const headerData = {
		headers: { 'access-token': accessToken },
	};

	return await axiosInstance.get(API_LIFF + '/questions/types', headerData);
}

export async function CLIENT_GET_APPOINTMENTS(
	storeId,
	accessToken,
	calendarFrom,
	calendarTo,
) {
	const paramData = {
		headers: { 'access-token': accessToken },
		params: {
			from: calendarFrom,
			to: calendarTo,
		},
	};

	return await axiosInstance.get(
		API_LIFF + '/store/' + storeId + '/schedule',
		paramData,
	);
}

export async function CLIENT_APPLY(data) {
	if (data.handleType === 'image') {
		const headerData = {
			headers: {
				'access-token': data.accessToken,
				'content-type': 'multipart/form-data',
			},
		};

		const formData = new FormData();
		formData.append('storeId', data.storeId);
		formData.append('applicationId', data.applicationId);
		formData.append('questionId', data.questionId);
		formData.append('answer', '');

		data.picUrl.forEach((image) => formData.append('picUrl', image));

		return await axiosInstance.post(
			API_LIFF + '/store/' + data.storeId + '/apply',
			formData,
			headerData,
		);
	} else if (data.handleType === 'resume') {
		const headerData = {
			headers: {
				'access-token': data.accessToken,
				'content-type': 'multipart/form-data',
			},
		};

		const formData = new FormData();
		formData.append('storeId', data.storeId);
		formData.append('applicationId', data.applicationId);
		formData.append('questionId', data.questionId);
		formData.append('answer', JSON.stringify(data.answer));
		formData.append('picUrl', data.picUrl);

		return await axiosInstance.post(
			API_LIFF + '/store/' + data.storeId + '/apply',
			formData,
			headerData,
		);
	} else {
		const headerData = {
			headers: { 'access-token': data.accessToken },
		};

		const paramData = {
			applicationId: data.applicationId,
			questionId: data.questionId,
			answer: data.answer,
		};

		return await axiosInstance.post(
			API_LIFF + '/store/' + data.storeId + '/apply',
			paramData,
			headerData,
		);
	}
}

export async function CLIENT_REFILL(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('picUrl', data.picUrl.file);
	return await axiosInstance.put(
		API_LIFF + '/store/' + data.storeId + '/resume/upload',
		formData,
		headerData,
	);
}

export async function CLIENT_APPLY_RESUME(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	for (var key in data) {
		if (key === 'resumeBgs' || key === 'resumeDes') {
			formData.append(key, JSON.stringify(data[key]));
		} else {
			formData.append(key, data[key]);
		}
	}

	return await axiosInstance.post(
		API_LIFF + '/store/' + data.storeId + '/resume',
		formData,
		headerData,
	);
}

export async function CLIENT_APPLY_IDENTITY(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('storeId', data.storeId);
	formData.append('applicationId', data.applicationId);
	formData.append('identity1Type', data.identity1Type);

	if (data.identity2Type) {
		formData.append('identity2Type', data.identity2Type);
	}

	if (data.identity3Type) {
		formData.append('identity3Type', data.identity3Type);
	}

	if (data.other1Text) {
		formData.append('other1Text', data.other1Text);
	}

	if (data.other2Text) {
		formData.append('other2Text', data.other2Text);
	}

	if (data.other3Text) {
		formData.append('other3Text', data.other3Text);
	}

	formData.append('identity1Pic', data.identity1Pic);
	formData.append('identity2Pic', data.identity2Pic);
	formData.append('identity3Pic', data.identity3Pic);

	return await axiosInstance.post(
		API_LIFF + '/store/' + data.storeId + '/identity',
		formData,
		headerData,
	);
}

export async function CLIENT_APPLY_IDENTITY_TEXT(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
		},
	};

	const paramData = {
		storeId: data.storeId,
		applicationId: data.applicationId,
		other4Text: data.other4Text,
		other5Text: data.other5Text,
	};

	return await axiosInstance.post(
		API_LIFF + '/store/' + data.storeId + '/identity/text',
		paramData,
		headerData,
	);
}

export async function CLIENT_APPLY_KOZA(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('provider', data.provider);
	formData.append('branch', data.branch);
	formData.append('description', data.description);
	formData.append('accId', data.accId);
	formData.append('accName', data.accName);
	formData.append('picUrl', data.picUrl || undefined);

	return await axiosInstance.post(
		API_LIFF + '/store/' + data.storeId + '/koza',
		formData,
		headerData,
	);
}

export async function CLIENT_APPLY_AGREEMENT(data) {
	const headerData = {
		headers: {
			'access-token': data.accessToken,
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('hasChecked', data.hasChecked);
	formData.append('picUrl', data.picUrl || undefined);

	return await axiosInstance.post(
		API_LIFF + '/store/' + data.storeId + '/consent',
		formData,
		headerData,
	);
}

export async function GET_LOGO() {
	return await axiosInstance.get(BASE_URL + '/logo');
}

export async function UPLOAD_LOGO(logo) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('picUrl', logo);

	return await axiosInstance.put(BASE_URL + '/m/logo', formData, headerData);
}

export async function GET_FAVICON() {
	return await axiosInstance.get(BASE_URL + '/favicon');
}

export async function UPLOAD_FAVICON(favicon) {
	const headerData = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	const formData = new FormData();
	formData.append('picUrl', favicon);

	return await axiosInstance.put(
		BASE_URL + '/m/favicon',
		formData,
		headerData,
	);
}

export async function GET_PUBLIC_SETTINGS() {
	return await axiosInstance.get(BASE_URL + '/settings');
}

export async function UPDATE_SYSTEM_COLOR(data) {
	const paramData = {
		label: SETTINGS_LABEL_SYSTEM_COLOR,
		isPublic: true,
		valueString: data.value,
	};

	return await axiosInstance.put(
		API_SETTINGS + '/' + SETTINGS_KEY_SYSTEM_COLOR,
		paramData,
	);
}

export async function UPDATE_SYSTEM_TITLE(data) {
	const paramData = {
		label: SETTINGS_LABEL_SYSTEM_TITLE,
		isPublic: true,
		valueString: data.value,
	};

	return await axiosInstance.put(
		API_SETTINGS + '/' + SETTINGS_KEY_SYSTEM_TITLE,
		paramData,
	);
}

export const GET_PUBLIC_HOLIDAYS = async () => {
	return await axios.get(PUBLIC_HOLIDAY_URL);
};

export async function MOBILE_GET_QUESTIONS() {
	return await axiosInstance.get(BASE_URL + '/m/questions/list');
}

export const ADMIN_GET_NOTIFICATIONS = async () => {
	return await axiosInstance.get(BASE_URL + '/m/notification');
};

export const ADMIN_GET_QUESTIONS_LIST = async () => {
	return await axiosInstance.get(`${API_QUESTIONS}/list`);
};

export const UPDATE_REFILL = async (data) => {
	return await axiosInstance.post(
		`${API_STORES}/${data.storeId}/applications/${data.applicationId}/refill`,
		{
			refillStatus: data.refillStatus,
		},
	);
};

export async function CLIENT_GET_LOCATIONLIST(accessToken, storeId) {
	const headerData = {
		headers: {
			'access-token': accessToken,
		},
	};
	return await axiosInstance.get(
		API_LIFF + '/store/' + storeId + '/locations',
		headerData,
	);
}
